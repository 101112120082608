import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { getProjectType } from '../Endpoints/UriPaths';
import instance from '../Endpoints/interceptor';
import { FormHelperText } from '@mui/material';
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';

export default function ProjectTypeField({
  programmatuur,
  setProgrammatuur,
  productSoftware,
  setProductSoftware,
  twoFields,
  setTwoFields,
  handleOnChange,
  project,
  hasInitialData,
  isDisableInputField,
  isDisableProjectType,
  setProjecTypeId
}) {

  const [listProjTypes, setListProjTypes] = useState([]);
  const [projectType, setProjectType] = React.useState("");
  const [displayFields, setDisplayFields] = useState(false);
  const [displayProductFields, setDisplayProductFields] = useState(false);
  const [displayTWOFields, setDisplayTWOFields] = useState(false);

  const [projTwoFields, setProjTwoFields] = useState({});
  const [projProductSoftware, setProjProductSoftware] = useState({});
  const [projProgrammatuur, setProjProgrammatuur] = useState({});
  const maxLength = 1500;
  const maxLengthMsg = "Maximale lengte bereikt";

  useEffect(() => {
    getProjectTypes();

    if (project === undefined) {
      handleProjectTypeChange('')
    }
    else {
      handleProjectTypeChange(project.type);
      setProjTwoFields(twoFields);
      setProjProductSoftware(productSoftware);
      setProjProgrammatuur(programmatuur);
    }
    // eslint-disable-next-line
  }, [])

  const getProjectTypes = () => {
    instance.get(getProjectType())
        .then(function (response) {
            setListProjTypes(response.data);
            console.log("projTypes: ", response.data)
        })
        .catch(function (error) {
        });
  }

  const handleProjectTypeChange = (value) => {
    setProjectType(value);

    // if programmatur or productieproces is selected
    if (value === 'Product' || value === 'Productieproces' || value === 'Programmatuur') {
      setDisplayFields((preState) => preState = true);
      setDisplayTWOFields((prevState) => prevState = false);
      setDisplayProductFields((prevState) => prevState = false);

      if (!hasInitialData) {
        setProductSoftwareBlank();
        setTwoFieldsBlank();
      }

    } else if (value === 'Product/Software') {
      setDisplayProductFields((prevState) => prevState = true);
      setDisplayFields((preState) => preState = false);
      setDisplayTWOFields((prevState) => prevState = false);

      if (!hasInitialData) {
        setProgrammatuurBlank();
        setTwoFieldsBlank();
      }

    } else if (value === 'Technisch-wetenschappelijk onderzoek') {
      setDisplayTWOFields((prevState) => prevState = true);
      setDisplayFields((preState) => preState = false);
      setDisplayProductFields((prevState) => prevState = false);

      if (!hasInitialData) {
        setProgrammatuurBlank();
        setProductSoftwareBlank();
      }

    }
  }

  function handleProjectId(event) {
    if(typeof setProjecTypeId === 'function'){
      const projTypeId = event.currentTarget.getAttribute('data-item-id');
      setProjecTypeId(projTypeId);
    }
   
  }

  const handleOnChangeTWOFields = (e) => {
    setTwoFields({
      ...twoFields,
      [e.target.name]: e.target.value
    });

    setProjTwoFields({
      ...projTwoFields,
      [e.target.name]: e.target.value
    });
  }

  const handleOnChangeProductSoftware = (e) => {
    setProductSoftware({
      ...productSoftware,
      [e.target.name]: e.target.value
    });

    setProjProductSoftware({
      ...projProductSoftware,
      [e.target.name]: e.target.value
    });
  }

  const handleOnChangeProgrammatuur = (e) => {
    setProgrammatuur({
      ...programmatuur,
      [e.target.name]: e.target.value
    });

    setProjProgrammatuur({
      ...projProgrammatuur,
      [e.target.name]: e.target.value
    });

  }

  const setProgrammatuurBlank = () => {
    setProgrammatuur({
      technicalProblem: '',
      solution: '',
    });
  }

  const setProductSoftwareBlank = () => {
    setProductSoftware({
      technicalProblemHardware: '',
      solutionHardware: '',
      technicalProblemSoftware: '',
      solutionSoftware: '',
    });
  }

  const setTwoFieldsBlank = () => {
    setTwoFields({
      reason: '',
      researchQuestion: '',
      researchSetUp: '',
      desiredOutcome: '',
      technologicalResearchArea: '',
    });
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
        <FormControl sx={{ marginBottom: 1, marginRight: 1, minWidth: 300 }}>
          <InputLabel id="demo-simple-select-label">Project type</InputLabel>
          <Select
            value={projectType}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="projectType"
            label="Project Type"
            disabled= {isDisableProjectType}
            onChange={(e) => {handleProjectTypeChange(e.target.value);handleOnChange(e)}}
          >
            {listProjTypes.map((option) => (
              <MenuItem key={option.id} value={option.type} data-item-id={option.id} onClick={handleProjectId}>
                {option.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {displayTWOFields &&
          <Box className='two-additional-fields'>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projTwoFields.reason}
                    fullWidth
                    className='field'
                    id="reason"
                    label="Aanleiding"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="reason"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                        <InputAdornment position="end">
                          {projTwoFields.reason?.length}/{maxLength}
                        </InputAdornment>
                    }}
                    onChange={handleOnChangeTWOFields}
                    inputProps={{ maxLength: maxLength }}
                  />
                  {projTwoFields.reason?.length >= maxLength && (
                    <FormHelperText error>{maxLengthMsg}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projTwoFields.researchQuestion}
                    fullWidth
                    className='field'
                    id="researchQuestion"
                    label="Onderzoeksvragen"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="researchQuestion"
                    onChange={handleOnChangeTWOFields}
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                        <InputAdornment position="end">
                          {projTwoFields.researchQuestion?.length}/{maxLength}
                        </InputAdornment>,
                    }}
                    inputProps={{ maxLength: maxLength }}
                  />
                  {projTwoFields.researchQuestion?.length >= maxLength && (
                    <FormHelperText error>{maxLengthMsg}</FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projTwoFields.researchSetUp}
                    fullWidth
                    className='field'
                    id="researchSetUp"
                    label="Opzet en uitvoering"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="researchSetUp"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                        <InputAdornment position="end">
                          {projTwoFields.researchSetUp?.length}/{maxLength}
                        </InputAdornment>,
                    }}
                    onChange={handleOnChangeTWOFields}
                    inputProps={{ maxLength: maxLength }}
                  />
                  {projTwoFields.researchSetUp?.length >= maxLength && (
                    <FormHelperText error>{maxLengthMsg}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projTwoFields.desiredOutcome}
                    fullWidth
                    className='field'
                    id="desiredOutcome"
                    label="Beoogde uitkomsten"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="desiredOutcome"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                        <InputAdornment position="end">
                          {projTwoFields.desiredOutcome?.length}/{maxLength}
                        </InputAdornment>,
                    }}
                    onChange={handleOnChangeTWOFields}
                    inputProps={{ maxLength: maxLength }}
                  />
                  {projTwoFields.desiredOutcome?.length >= maxLength && (
                    <FormHelperText error>{maxLengthMsg}</FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projTwoFields.technologicalResearchArea}
                    fullWidth
                    className='field'
                    id="technologicalResearchArea"
                    label="Technologiegebied onderzoek"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="technologicalResearchArea"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                        <InputAdornment position="end">
                          {projTwoFields.technologicalResearchArea?.length}/{maxLength}
                        </InputAdornment>,
                    }}
                    onChange={handleOnChangeTWOFields}
                    inputProps={{ maxLength: maxLength }}
                  />
                  {projTwoFields.technologicalResearchArea?.length >= maxLength && (
                    <FormHelperText error>{maxLengthMsg}</FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        }

        {displayFields &&
          <Box className='additional-fields'>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projProgrammatuur.technicalProblem}
                    fullWidth
                    className='field'
                    id="technicalProblem"
                    label="Technisch knelpunt"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="technicalProblem"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                        <InputAdornment position="end">
                          {projProgrammatuur.technicalProblem?.length}/{maxLength}
                        </InputAdornment>,
                    }}
                    onChange={handleOnChangeProgrammatuur}
                    inputProps={{ maxLength: maxLength }}
                  />
                   {projProgrammatuur.technicalProblem?.length >= maxLength && (
                      <FormHelperText error>{maxLengthMsg}</FormHelperText>
                    )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projProgrammatuur.solution}
                    fullWidth
                    className='field'
                    id="solution"
                    label="Beoogde oplossing"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="solution"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                        <InputAdornment position="end">
                          {projProgrammatuur.solution?.length}/{maxLength}
                        </InputAdornment>,
                    }}
                    onChange={handleOnChangeProgrammatuur}
                    inputProps={{ maxLength: maxLength }}
                  />
                   {projProgrammatuur.solution?.length >= maxLength && (
                      <FormHelperText error>{maxLengthMsg}</FormHelperText>
                    )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        }

        {displayProductFields &&
          <Box className='product-additional-fields'>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projProductSoftware.technicalProblemHardware}
                    className='field'
                    fullWidth
                    id="technicalProblemHardware"
                    label="Technisch knelpunt hardware"
                    multiline
                    rows={3}
                    variant="outlined"
                    name="technicalProblemHardware"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                      <InputAdornment position="end">
                        {projProductSoftware.technicalProblemHardware?.length}/{maxLength}
                      </InputAdornment>,
                    }}
                    onChange={handleOnChangeProductSoftware}
                    inputProps={{ maxLength: maxLength }}
                  />
                  {projProductSoftware.technicalProblemHardware?.length >= maxLength && (
                      <FormHelperText error>{maxLengthMsg}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projProductSoftware.solutionHardware}
                    fullWidth
                    className='field'
                    id="solutionHardware"
                    label="Beoogde oplossing hardware"
                    multiline
                    rows={3}
                    variant="outlined"
                    name="solutionHardware"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                      <InputAdornment position="end">
                        {projProductSoftware.solutionHardware?.length}/{maxLength}
                      </InputAdornment>,
                    }}
                    onChange={handleOnChangeProductSoftware}
                    inputProps={{ maxLength: maxLength }}
                  />
                   {projProductSoftware.solutionHardware?.length >= maxLength && (
                      <FormHelperText error>{maxLengthMsg}</FormHelperText>
                    )}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projProductSoftware.technicalProblemSoftware}
                    fullWidth
                    className='field'
                    id="technicalProblemSoftware"
                    label="Technisch knelpunt software"
                    variant="outlined"
                    multiline
                    rows={4}
                    name="technicalProblemSoftware"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                      <InputAdornment position="end">
                        {projProductSoftware.technicalProblemSoftware?.length}/{maxLength}
                      </InputAdornment>,
                    }}
                    onChange={handleOnChangeProductSoftware}
                    inputProps={{ maxLength: maxLength }}
                  />
                  {projProductSoftware.technicalProblemSoftware?.length >= maxLength && (
                    <FormHelperText error>{maxLengthMsg}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    value={projProductSoftware.solutionSoftware}
                    fullWidth
                    className='field'
                    id="solutionSoftware"
                    label="Beoogde oplossing software"
                    variant="outlined"
                    multiline
                    rows={4}
                    name="solutionSoftware"
                    InputProps={{
                      readOnly: isDisableInputField,
                      endAdornment: 
                      <InputAdornment position="end">
                        {projProductSoftware.solutionSoftware?.length}/{maxLength}
                      </InputAdornment>,
                    }}
                    onChange={handleOnChangeProductSoftware} 
                    inputProps={{ maxLength: maxLength }}
                    />
                    {projProductSoftware.solutionSoftware?.length >= maxLength && (
                      <FormHelperText error>{maxLengthMsg}</FormHelperText>
                    )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      </Box>
    </>
  );
}
