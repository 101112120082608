import './App.css';
import './Search/search';
import Search from './Search/search';
import Login from './Container/Login/index';
import { useRoutes } from "react-router-dom";

function App() {
  const superAdminRoutes = useRoutes([ {
    path: "/login",
    element: <Login />,
    children: []
  },
  {
    path: "/",
    element: <Search />
  },
  
  { path: "*", element: <Login />}]);

  return ( <div>
    {superAdminRoutes}
  </div> );
}

export default App;
