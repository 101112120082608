import axios from "axios";
import Swal from "sweetalert2";
import Connection from "./connection";
const instance = axios.create({
    baseURL: Connection.getBaseUrl(),
});

instance.defaults.timeout = 150000;

instance.interceptors.request.use(
  (config) => {
    if (window.navigator.onLine) {
      const token = localStorage.getItem(`token`);

      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Accept=`application/json`;
      }
      return config;
    } else {
      return Promise.reject(new Error("Check Your Internet Connection"));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(response => response, error => {
    if (error.response.status === 400) {
      Swal.fire(error.response.data.message, "", "error");
    } else if (error.response.status === 401) {
      localStorage.clear();
      // Swal.fire("Unauthorized accesssssss!", "", "error");
      
      window.location.href = "/login";
    } else if (error.response.status === 500) {
      Swal.fire("Internal server error!", "", "error");
    }
    return Promise.reject(error);
  });


export default instance;
