import {
  Modal,
  Typography,
  TextField,
  FormHelperText
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Stack } from "@mui/system";
import { useState } from 'react';
import ProjectTypeField from "../../components/ProjectTypeField";
import { useForm } from "./useForm";
import CreateIcon from '@material-ui/icons/Create';
import { putProject } from "../../Endpoints/UriPaths";
import InputAdornment from '@mui/material/InputAdornment';
import { putResolutieCrm } from "../../Endpoints/UriPaths";
import instance from "../../Endpoints/interceptor";

export default function DataTable({ handlePageState, handlePageSize, pageState, isAdvanceSearch, handleOnSearchBasic, handleOnSearchAdvance}) {
const [isDisableProjectType, setIsDisableProjectType] = useState(false);
const [isDisableProjectName, setIsDisableProjectName] = useState(false);

const [open, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
const maxLength = 1500;
const maxLengthMsg = "Maximale lengte bereikt";

const columns = [

  { field: 'name', headerName: 'Projectnaam', width: 400 },
  { field: 'project_number', headerName: 'Projectnummer', width: 150 },
  { field: 'type', headerName: 'Project Type', width: 300 },
  { field: 'description', headerName: 'Projectbeschrijving', width: 450 },
  {
    field: 'button',
    headerName: 'Actie',
    width: 120,
    renderCell: (params) => {
      return (
        <Button variant="contained" color="primary" onClick={() => handleButtonClick(params.row)}>
          <CreateIcon/>
        </Button>
      );
    },
  },
];
const initialValues = {
  name: '',
  project_type_id: '',
  project_number: '',
  description: '',
}
const [projecTypeId, setProjecTypeId] = useState('');
const [projVal, handleOnChange] = useForm(initialValues);

const handleButtonClick = (row) => {
  console.log(row)
  setProjecTypeId('')
  handleOpen()
  projVal.name = row.name;
  projVal.project_number = row.project_number;
  projVal.description = row.description;
  projVal.type = row.type;
  projVal.project_type_id = row.project_type_id;
  projVal.project_id = row.project_id;
  projVal.projectwbso_id = row.projectwbso_id;
  projVal.isCrm = row.isCrm;

  if(row.isCrm)
  {
    setIsDisableProjectType(true)
    setIsDisableProjectName(true)

  }
  else{
    setIsDisableProjectType(false)
    setIsDisableProjectName(false)

  }

  setTwoFields({
    ...twoFields,
    reason: row.reason,
    researchQuestion: row.research_questions,
    researchSetUp: row.research_set_up,
    desiredOutcome: row.desired_outcome,
    technologicalResearchArea: row.technological_research_area,
  })

  setProductSoftware({
    ...productSoftware,
    technicalProblemHardware: row.technical_problem_hardware,
    solutionHardware: row.solution_hardware,
    technicalProblemSoftware: row.technical_problem_software,
    solutionSoftware: row.solution_software,
  });

  setProgrammatuur({
    ...programmatuur,
    technicalProblem: row.technical_problem,
    solution: row.solution,
  });

};

const [programmatuur, setProgrammatuur] = useState({
  technicalProblem: '',
  solution: '',
});

const [productSoftware, setProductSoftware] = useState({
  technicalProblemHardware: '',
  solutionHardware: '',
  technicalProblemSoftware: '',
  solutionSoftware: '',
});

const [twoFields, setTwoFields] = useState({
  reason: '',
  researchQuestion: '',
  researchSetUp: '',
  desiredOutcome: '',
  technologicalResearchArea: '',
});

const updateProject = () => 
{
    if (projecTypeId > 0) {
      projVal.project_type_id = projecTypeId;
    }
    if( projVal.description?.length                      >= maxLength ||
        programmatuur.technicalProblem?.length           >= maxLength ||
        programmatuur.solution?.length                   >= maxLength ||
        productSoftware.technicalProblemHardware?.length >= maxLength ||
        productSoftware.solutionHardware?.length         >= maxLength ||
        productSoftware.technicalProblemSoftware?.length >= maxLength ||
        productSoftware.solutionSoftware?.length         >= maxLength ||
        twoFields.reason?.length                         >= maxLength ||
        twoFields.researchQuestion?.length               >= maxLength ||
        twoFields.researchSetUp?.length                  >= maxLength ||
        twoFields.desiredOutcome?.length                 >= maxLength ||
        twoFields.technologicalResearchArea?.length      >= maxLength 
      )
   {
       Swal.fire({
           icon: 'error',
           title: 'Oops...',
           text: 'Maximale lengte bereikt.',
       })
   }
   else
   {
      Swal.fire({
        title: "Weet je het zeker?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ja",
        cancelButtonText: "Nee",
        confirmButtonColor: "primary",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
      
          if(projVal.isCrm){
            instance.put(putResolutieCrm(projVal.projectwbso_id),
            {
              technischKnelpunt          : programmatuur.technicalProblem,
              beoogdeOplossing           : programmatuur.solution,
              technischKnelpuntHardware  : productSoftware.technicalProblemHardware,
              beoogdeOplossingHardware   : productSoftware.solutionHardware,
              technischKnelpuntSoftware  : productSoftware.technicalProblemSoftware,
              beoogdeOplossingSoftware   : productSoftware.solutionSoftware,
              aanleiding                 : twoFields.reason,
              onderzoeksvragen           : twoFields.researchQuestion,
              opzetEnUitvoering          : twoFields.researchSetUp,
              beoogdeUitkomsten          : twoFields.desiredOutcome,
              technologiegebiedOnderzoek : twoFields.technologicalResearchArea
            })
            .then(function (response) {
              if (isAdvanceSearch) {
                handleOnSearchAdvance()
              }
              else {
                handleOnSearchBasic()
              }
              Swal.fire("Succesvol geüpdatet!", "", "success");
            })
            .catch(function (error) {
            });
          }
          else{

            instance.put(putProject(projVal.project_id),
            {
              project_type_id             : projVal.project_type_id,
              project_number              : projVal.project_number,
              name                        : projVal.name,
              description                 : projVal.description,
              technical_problem           : programmatuur.technicalProblem,
              solution                    : programmatuur.solution,
              technical_problem_hardware  : productSoftware.technicalProblemHardware,
              solution_hardware           : productSoftware.solutionHardware,
              technical_problem_software  : productSoftware.technicalProblemSoftware,
              solution_software           : productSoftware.solutionSoftware,
              reason                      : twoFields.reason,
              research_questions          : twoFields.researchQuestion,
              research_set_up             : twoFields.researchSetUp,
              desired_outcome             : twoFields.desiredOutcome,
              technological_research_area : twoFields.technologicalResearchArea
            })
            .then(function (response) {
              if (isAdvanceSearch) {
                handleOnSearchAdvance()
              }
              else {
                handleOnSearchBasic()
              }
              Swal.fire("Succesvol geüpdatet!", "", "success");
            })
            .catch(function (error) {
            });
          }
          
          handleClose();
          
        } else if (result.isDenied) {
          Swal.fire("Wijzigingen worden niet opgeslagen", "info");
        }
      });
  }
}

  return (
    <>
      <div style={{ height: 700, width: '100%' }}>
      <DataGrid
        autoHeight
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          handlePageState(newPage)
        }}
        onPageSizeChange={(newPageSize) => {
          handlePageSize(newPageSize)
        }}
        columns={columns}
      />
    </div>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: '5px',
          boxShadow: 24,
          p: 4,
          width: "40%"

        }}
          md={12}
          xs={12}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography variant="overline" style={{ textAlign: "center" }}>
                Project Details
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>               
            <TextField 
                  fullWidth 
                  value={projVal.name}  
                  name="name"
                  id="name" 
                  label="Projectnaam" 
                  variant="outlined"
                  onChange={handleOnChange}
                  InputProps={{
                    readOnly: isDisableProjectName,       
                  }}
                 
            />
            </Grid> 
            <Grid item xs={12} lg={12}>
            <TextField 
                  fullWidth 
                  value={projVal.project_number} 
                  name="project_number"
                  id="projectNumber" 
                  label="Projectnummer" 
                  variant="outlined"
                  onChange={handleOnChange}        
            />
            </Grid> 
            <Grid item xs={12} lg={12}>
            <TextField 
                  fullWidth 
                  value={projVal.description} 
                  name="description"
                  id="projectDescription" 
                  label="Projectbeschrijving" 
                  variant="outlined"
                  onChange={handleOnChange}  
                  inputProps={{ maxLength: maxLength }} 
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                        {projVal.description?.length}/{maxLength}
                    </InputAdornment>,
                }}    
            />
             {projVal.description?.length >= maxLength && (
                <FormHelperText error>{maxLengthMsg}</FormHelperText>
              )}
            </Grid> 
            <Grid item md={12} xs={12}>
            </Grid>        
            <Grid item md={12} xs={12}>
              <ProjectTypeField
                programmatuur               = {programmatuur}
                setProgrammatuur            = {setProgrammatuur}
                productSoftware             = {productSoftware}
                setProductSoftware          = {setProductSoftware}
                twoFields                   = {twoFields}
                setTwoFields                = {setTwoFields}
                handleOnChange              = {handleOnChange}        
                project                     = {projVal}
                hasInitialData              = {true} 
                isDisableInputField         = {false}
                isDisableProjectType        = {isDisableProjectType}
                setProjecTypeId             = {setProjecTypeId}                   
              />
            </Grid>       
            <Grid item md={12} xs={12}>
              <Stack direction="row" spacing={2} paddingTop="10px" marginTop="10px">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Annuleren
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  sx={{
                    ':hover': {
                      bgcolor: 'primary.main',
                      color: 'white',
                    }
                  }}
                  onClick={updateProject}
                >
                  Opslaan
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}