
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import SearchBar from './components/searchBar';
import AdvancedSearch from './components/advancedSearch';
import DataTable from './components/dataTable';
import { useForm } from './components/useForm';
import { postSearchBasic, postSearchAdvance } from '../Endpoints/UriPaths';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import myLogo from '../../src/Assets/hanlogowhite.png';
import Project from '../Project/Index';
import instance from '../Endpoints/interceptor';
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 0,
    borderRadius: 20,
    backgroundColor: '#1597E5',
    color: '#FFFFFF',
    padding: '5px 15px',
    '&:hover': {
      backgroundColor: '#009EFF',
      color: '#FFFFFF',

    },
  },
  iconColor: {
    color: '#FFFFFF',
    padding: '5px 15px',
    '&:hover': {
      color: '#009EFF',
    },
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Search() {

  const classes = useStyles();
  const navigate = useNavigate();
  const [isShowAdvance, setIsShowAdvance] = useState(false);
  const [windowWidth, setWindowWidth] = useState(1300);

  const handleClick = event => {
    setIsShowAdvance(current => !current);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[])

  const initialValues = {
    projectNumber: '',
    projectName: '',
    projectType: '',
    projectDescription: '',
    relationshipName: '',
    search: '',
  }

  const [values, handleOnChange] = useForm(initialValues)
  const [programmatuur, setProgrammatuur] = useState({
    technicalProblem: '',
    solution: '',
  });
  const [productSoftware, setProductSoftware] = useState({
    technicalProblemHardware: '',
    solutionHardware: '',
    technicalProblemSoftware: '',
    solutionSoftware: '',
  });
  const [twoFields, setTwoFields] = useState({
    reason: '',
    researchQuestion: '',
    researchSetUp: '',
    desiredOutcome: '',
    technologicalResearchArea: '',
  });

  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    isAdvanceSearch: false
  })

  useEffect(() => {
    if (pageState.isAdvanceSearch) {
      handleOnSearchAdvance();
    }
    else {
      handleOnSearchBasic();
    }
    // eslint-disable-next-line
  }, [pageState.page, pageState.pageSize])

  const handleOnSearchBasic = () => {
    setPageState(old => ({ ...old, isLoading: true}));
    instance.post(postSearchBasic(), {
      search: values.search,
      page: pageState.page,
      limit: pageState.pageSize
    })
      .then(function (response) {
        setPageState(old => ({ ...old, isLoading: false, isAdvanceSearch: false, data: response.data.data, total: response.data.total }))
      })
      .catch(function (error) {

      });
  }

  const handleOnSearchAdvance = () => {
    setPageState(old => ({ ...old, isLoading: true}));
    instance.post(postSearchAdvance(), {
      project_name               : values.projectName,
      project_type               : values.projectType,
      project_description        : values.projectDescription,
      project_number             : values.projectNumber,
      relationship_name          : values.relationshipName,
      technical_problem          : programmatuur.technicalProblem,
      solution                   : programmatuur.solution,
      technical_problem_hardware : productSoftware.technicalProblemHardware,
      solution_hardware          : productSoftware.solutionHardware,
      technical_problem_software : productSoftware.technicalProblemSoftware,
      solution_software          : productSoftware.solutionSoftware,
      reason                     : twoFields.reason,
      research_question          : twoFields.researchQuestion,
      research_setUp             : twoFields.researchSetUp,
      desired_outcome            : twoFields.desiredOutcome,
      technological_researchArea : twoFields.technologicalResearchArea,
      page                       : pageState.page,
      limit                      : pageState.pageSize
    })
      .then(function (response) {
        setPageState(old => ({ ...old, isLoading: false, isAdvanceSearch: true, data: response.data.data, total: response.data.total }))
      })
      .catch(function (error) {

      });
  }

  const handlePageState = (newPage) => {
    setPageState(old => ({ ...old, page: newPage + 1 }))
  }

  const handlePageSize = (newPageSize) => {
    setPageState(old => ({ ...old, pageSize: newPageSize }))
  }

  const logout = () => {
    instance.post("logout")
        .then((value) => {
          localStorage.clear();
          return value;
        });
    localStorage.removeItem("accountInfo");
    navigate("/login");
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              height: 300,
              backgroundColor: 'primary.dark',
              borderBottom: 5,
              borderColor: '#7DE5ED'
            }}
          >
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} lg={3}  >
                <img src={myLogo} alt="My Logo" height={windowWidth > 1298 ? 180 : 40} style={{marginLeft:20, marginTop: windowWidth > 1298 ? 40 : 0 }} />
              </Grid>
              <Grid item xs={12} lg={6}>
                {windowWidth > 1298 && 
                <Box height={55}>
                </Box>}
                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={12} lg={12}  >
                  <SearchBar handleOnChange={handleOnChange}
                  values={values}
                  handleOnSearchBasic={handleOnSearchBasic}
                  />
                </Grid>     
                 <Button className={classes.button} onClick={handleClick}>
                    Geavanceerd Zoeken
                  </Button>
                </Grid>              
              </Grid>
              <Grid className={classes.bottom} item xs={12} lg={3}  >
              <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={12} lg={9}  >
                  <Project search={ pageState.isAdvanceSearch ? handleOnSearchAdvance : handleOnSearchBasic } />
                  </Grid>
                  <Grid item xs={12} lg={3}  >
                  <ListItemButton onClick={() => logout()}>
                    <ListItemIcon>
                      <LogoutIcon className={classes.iconColor} />
                    </ListItemIcon>
                  </ListItemButton>
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid>
           

          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box>

            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={11} lg={7}>
                <AdvancedSearch handleOnChange={handleOnChange}
                  handleOnSearchAdvance={handleOnSearchAdvance}
                  programmatuur               = {programmatuur}
                  setProgrammatuur            = {setProgrammatuur}
                  productSoftware             = {productSoftware}
                  setProductSoftware          = {setProductSoftware}
                  twoFields                   = {twoFields}
                  setTwoFields                = {setTwoFields}
                  isShowAdvance               = {isShowAdvance}
                />
              </Grid>
              <Grid item xs={11} lg={10}>
                <DataTable handlePageState={handlePageState}
                  handlePageSize={handlePageSize}
                  pageState={pageState}
                  projectType={values.projectType}
                  isAdvanceSearch={pageState.isAdvanceSearch}
                  handleOnSearchBasic={handleOnSearchBasic}
                  handleOnSearchAdvance={handleOnSearchAdvance}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>

  );
}



