import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import ProjectTypeField from '../../components/ProjectTypeField';
import { Stack } from '@mui/system';
import './advancedSearch.css';

export default function AdvancedSearch({
  handleOnChange, 
  handleOnSearchAdvance, 
  programmatuur,
  setProgrammatuur,
  productSoftware,
  setProductSoftware,
  twoFields,
  setTwoFields,
  isShowAdvance
}) {
    
  return (    
    <> 
     <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} justifyContent="center"> 
        <Grid item xs={12} lg={12}>
          {isShowAdvance &&            
            <Box
              sx={{
                width: 1,
                height: 'auto',
                borderRadius: '16px',
                backgroundColor: '#F2FCFC',
                
              }}
            >
              <Box  sx={{display: 'flex',flexDirection: 'row',p: 2,m: 1,}}>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <TextField id="projectNumber" label="Projectnummer" variant="outlined" name="projectNumber"  onChange={handleOnChange}/>
                  <TextField id="projectName" label="Projectnaam" variant="outlined" name="projectName"  onChange={handleOnChange}/>
                  <TextField id="projectDescription" label="Projectbeschrijving" variant="outlined" name="projectDescription"  onChange={handleOnChange}/>           
                </Stack>

              </Box>
              <Box  sx={{display: 'flex',flexDirection: 'row',p: 2,m: 1,}}>
                <Stack
                  direction="row"
                  spacing={2}
                >
                 <ProjectTypeField
                    handleOnChange              ={handleOnChange}
                    programmatuur               = {programmatuur}
                    setProgrammatuur            = {setProgrammatuur}
                    productSoftware             = {productSoftware}
                    setProductSoftware          = {setProductSoftware}
                    twoFields                   = {twoFields}
                    setTwoFields                = {setTwoFields}
                    hasInitialData              = {false}
                    isDisableInputField         = {false}   
       
                    />                                 
                </Stack>
              </Box>      
              <Box sx={{display: 'flex',justifyContent: 'flex-end',p: 1,m: 1,}}>
                  <Button variant="contained" color="primary" onClick={handleOnSearchAdvance}>Zoeken</Button>           
              </Box>        
            </Box>
          }      
        </Grid>
      </Grid>  
     </Box>            
   </>
  );
}
