
import {
    Modal,
    TextField,
    Typography,
    FormHelperText
} from "@mui/material";
import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Stack } from "@mui/system";
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useForm } from "../Search/components/useForm";
import { postProject } from "../Endpoints/UriPaths";
import Swal from "sweetalert2";
import ProjectTypeField from "../components/ProjectTypeField";
import InputAdornment from '@mui/material/InputAdornment';
import instance from "../Endpoints/interceptor";

const useStyles = makeStyles({
    button: {
        background: 'transparent',
        border: '1px solid',
        borderColor: '#ffffff',
        borderRadius: 3,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#009EFF',
        },
        height: 48,
        padding: '0 30px',
    },
});

export default function Project({ search }) {
    const classes = useStyles();
    const maxLength = 1500;
    const maxLengthMsg = "Maximale lengte bereikt";

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const initialValues = {
        name: '',
        project_type_id: '',
        project_number: '',
        description: '',
    }
    const [projecTypeId, setProjecTypeId] = useState('');

    const [projVal, handleOnChange] = useForm(initialValues);

    const [programmatuur, setProgrammatuur] = useState({
        technicalProblem: '',
        solution: '',
    });

    const [productSoftware, setProductSoftware] = useState({
        technicalProblemHardware: '',
        solutionHardware: '',
        technicalProblemSoftware: '',
        solutionSoftware: '',
    });

    const [twoFields, setTwoFields] = useState({
        reason: '',
        researchQuestion: '',
        researchSetUp: '',
        desiredOutcome: '',
        technologicalResearchArea: '',
    });

    const addProject = () => {
        projVal.project_type_id = projecTypeId;
        if (projVal.name !== '' &&
            projVal.project_type_id !== '' &&
            projVal.project_number !== '' &&
            projVal.description !== ''
        ) {
            // eslint-disable-next-line
            if(projecTypeId == 1 || projecTypeId == 2 || projecTypeId == 3)
            {      
                projVal.solution                    = programmatuur.solution;
                projVal.technical_problem           = programmatuur.technicalProblem;
            }
            // eslint-disable-next-line
            if(projecTypeId == 4)
            {
                projVal.solution_hardware             = productSoftware.solutionHardware;
                projVal.solution_software             = productSoftware.solutionSoftware;
                projVal.technical_problem_hardware    = productSoftware.technicalProblemSoftware;
                projVal.technical_problem_software    = productSoftware.technicalProblemHardware;

            }
            // eslint-disable-next-line
            if(projecTypeId == 5)
            {
                projVal.desired_outcome             = twoFields.desiredOutcome;
                projVal.reason                      = twoFields.reason;
                projVal.research_questions          = twoFields.researchQuestion;
                projVal.research_set_up             = twoFields.researchSetUp;
                projVal.technological_research_area = twoFields.technologicalResearchArea;
            }

            if(projVal.description?.length                 >= maxLength ||
               projVal.solution?.length                    >= maxLength ||
               projVal.technical_problem?.length           >= maxLength ||
               projVal.solution_hardware?.length           >= maxLength ||
               projVal.solution_software?.length           >= maxLength ||
               projVal.technical_problem_hardware?.length  >= maxLength ||
               projVal.technical_problem_software?.length  >= maxLength ||
               projVal.desired_outcome?.length             >= maxLength ||
               projVal.reason?.length                      >= maxLength ||
               projVal.research_questions?.length          >= maxLength ||
               projVal.research_set_up?.length             >= maxLength ||
               projVal.technological_research_area?.length >= maxLength 
               )
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maximale lengte bereikt.',
                })
            }
            else
            {
                Swal.fire({
                    title: "Weet je het zeker?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    confirmButtonColor: "primary",
                    showLoaderOnConfirm: true,
                    reverseButtons: true,
                    preConfirm: () => {
                        instance.post(postProject(), projVal)
                            .then(function (response) {   
                                resetData();
                                handleClose();
                                search();
                                Swal.fire("Succesvol toegevoegd!", "", "success");
                            })
                            .catch(function (error) {
                                Swal.fire("Something went wrong.", "", "error");
                            });
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then((result) => {
                    if(result.isDenied) {
                        Swal.fire("Wijzigingen worden niet opgeslagen", "info");
                    }
                });
            }     
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Er zijn lege velden.',
            })
        }
    }

    const resetData = () => {
        projVal.name = '';
        projVal.project_number = '';
        projVal.description = '';
    }


    return (
        <>
            <Button className={classes.button} onClick={handleOpen} color="white">
                <AddIcon />
                Nieuw Project
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: '5px',
                    boxShadow: 24,
                    p: 4,
                    width: "40%"

                }}
                    md={12}
                    xs={12}
                >
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Typography variant="overline" style={{ textAlign: "center" }}>
                                Nieuw Project
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField fullWidth id="name" label="Projectnaam" variant="outlined" name="name" onChange={handleOnChange} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField fullWidth id="project_number" label="Projectnummer" variant="outlined" name="project_number" onChange={handleOnChange} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField 
                                fullWidth 
                                id="description" 
                                label="Beschrijving" 
                                variant="outlined" 
                                name="description" 
                                onChange={handleOnChange}
                                inputProps={{ maxLength: maxLength }}   
                                InputProps={{
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        {projVal.description?.length}/{maxLength}
                                    </InputAdornment>,
                                }}                   
                            />
                             {projVal.description?.length >= maxLength && (
                            <FormHelperText error>{maxLengthMsg}</FormHelperText>
                            )}
                        </Grid>
                          <Grid item md={12} xs={12}>
                            <ProjectTypeField
                                handleOnChange              = {handleOnChange}
                                programmatuur               = {programmatuur}
                                setProgrammatuur            = {setProgrammatuur}
                                productSoftware             = {productSoftware}
                                setProductSoftware          = {setProductSoftware}
                                twoFields                   = {twoFields}
                                setTwoFields                = {setTwoFields}
                                hasInitialData              = {false} 
                                isDisableInputField         = {false}
                                setProjecTypeId             = {setProjecTypeId}          
                                />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Stack direction="row" spacing={2} paddingTop="10px" marginTop="10px">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClose}
                                >
                                    Annuleren
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    sx={{
                                        ':hover': {
                                            bgcolor: 'primary.main',
                                            color: 'white',
                                        }
                                    }}
                                    onClick={addProject}
                                >
                                    Opslaan
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}



