import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

export default function SearchBar({handleOnChange,handleOnSearchBasic}) {

 
  return (  
    <>  
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 0,
          m: 1,
          
          borderRadius: 1,
        }}
      >
         <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          m: 1,
          width: '50%',
          borderRadius: 5,
          bgcolor: '#EAFDFC',
        }}
      >
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
        <InputBase
        sx={{ ml: 5, flex: 3 }}
        placeholder="Zoeken"
        inputProps={{ 'aria-label': 'search' }}
        name="search"
        onKeyPress={handleOnSearchBasic}
        onChange={handleOnChange}
      />
        </FormControl>
      
      <IconButton type="button" sx={{ p: '10px' }}  aria-label="search" onClick={handleOnSearchBasic}>
         <SearchIcon style={{color: "#1363DF"}}/>
      </IconButton>
      </Box>
      </Box>
   
   </>

  );
}
