import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import logo from "../../Assets/logo.png";
import Connection from '../../Endpoints/UriPaths';
const theme = createTheme();

const api = axios.create({
    baseURL: Connection.getBaseUrl(),
  });
export default function Login() {
    let navigate = useNavigate();
    const [errMsg, setErrorMsg] = useState("");
    const [isLogin, setIsLogin] = useState(false);
    const [pwd, setPassword] = useState("");
  
    const handleChangePass = (event) => {
      setPassword(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLogin(true);
      const data = new FormData(event.currentTarget);
      api
        .post("login", {
          email: data.get("email"),
          password: data.get("password"),
        })
        .then((response) => {
          if (response.status === 200) {
            var datas = response.data;
            localStorage.setItem('token', datas.token);    
            navigate("/");    
            setIsLogin(false);
          }
        })
        .catch((err) => {
          // Handle error
          setErrorMsg(err.response.data.message);
          if (err.response.data.message === "Invalid email or password.") {
            setPassword("");
          }
          setIsLogin(false);
        });
    };
  
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="logo-ContainerLogin">
              <Box
                component="img"
                sx={{
                  height: 150,
                  width: 150,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  mb: -3,
                }}
                src={logo}
              />
            </div>
            <br></br>
            <Grid container justifyContent="center">
                <Grid item>
                <Typography  sx={{fontSize:"20px",color:"#324894",fontWeight:"bold"}}>
                Sign In
            </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item>
                </Grid>
            </Grid>
            
            <Typography
              display="true"
              variant="caption"
              color="red"
              sx={{
                marginTop: "10px !important",
                marginBottom: "-10px !important",
                fontSize: "15px",
                // color: "white",
              }}
            >
              {errMsg}
            </Typography>
  
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                variant="standard"
                required
                fullWidth
                id="email"
                label="E-mailadres"
                name="email"
                autoComplete="false"
                autoFocus
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Wachtwoord"
                type="password"
                id="password"
                onChange={handleChangePass}
                value={pwd}
                autoComplete="current-password"
              />
              <Grid container justifyContent="center">
                <Grid item>
                    <LoadingButton
                    loading={isLogin}
                    loadingPosition="center"
                    variant="outlined"
                    type="submit"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Inloggen
                  </LoadingButton>
                </Grid>
               </Grid>
             
               <Grid container justifyContent="center">
                <Grid item>
                  {/* <Link href="#" variant="body2" sx={{color:"#808080"}}>
                    {"Wachtwoord vergeten?"}
                  </Link> */}
                </Grid>
               </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
  